import { JSX }	from "preact";

import * as fbModel from "@geotoura/shared/fbModel";

import * as actions	from "@geotoura/fb/actions";
import * as util	from "@geotoura/fb/util";
import { Counter }	from "@geotoura/fb/components/Counter";

export type DateProps	= Readonly<{
	screen:	fbModel.ScreenOfDate,
	answer:	fbModel.AnswerOfDate,
}>;

export const Date = ({ screen, answer }:DateProps):JSX.Element => {
	const setCounter1 = (val:number):void =>
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ADate>().atKey("counter1").atKey("counterValue").set(val)
		);

	const setCounter2 = (val:number):void =>
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ADate>().atKey("counter2").atKey("counterValue").set(val)
		);

	const setDate = (ev:JSX.TargetedInputEvent<HTMLInputElement>):void => {
		actions.doRefresh(
			actions.answerContent(screen.id).cast<fbModel.ADate>().atKey("date").set(ev.currentTarget.value)
		);
	};

	// TODO i18n this is calculated from UTC which is wrong:
	// the relevant timezone here is one in the geotoura office,
	// not UTC and not the one of the user, either
	const minDate = util.addDays(7);
	const maxDate = util.addDays(540);
	return (
		<div class="Date Screen">
			<Counter
				counterValue={answer.content.counter1.counterValue}
				countItem={screen.content.counter1}
				action={setCounter1}
			/>
			<div class="heading">{screen.content.dateTitle}</div>
			<div class="fb-inputwrapper">
				<input
					class="fb-input fb-date"
					type="date"
					onChange={setDate}
					value={answer.content.date}
					pattern="[0-9]{2}.[0-9]{2}.[0-9]{4}"
					placeholder="DD.MM.YYYY"
					min={minDate}
					max={maxDate}
				/>
				<div class="fa-light fa-calendar"></div>
			</div>
			<Counter
				counterValue={answer.content.counter2.counterValue}
				countItem={screen.content.counter2}
				action={setCounter2}
			/>
		</div>
	);
};
