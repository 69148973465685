import { JSX }	from "preact";

import * as fbModel		from "@geotoura/shared/fbModel";

import { useMessages }	from "@geotoura/fb/useMessages";

export type TextProps = Readonly<{
	screen:			fbModel.ScreenOfText,
	serverResponse:	string,
}>;

export const Text = ({ screen, serverResponse }:TextProps):JSX.Element => {
	const msg = useMessages();

	return (
		<div class="Text Screen">
			{
				serverResponse === "error" &&
				<div>
					<div class="heading">{msg.text.errorHeading}</div>
					<div class="text">{msg.text.errorText1}</div>
					<div class="text">{msg.text.errorText2}
						<a href={ msg.text.mailLink.href } class="textlink">{msg.text.mailLink.text}</a>
						{msg.text.errorText3}
						<a href={ msg.text.phoneLink.href } class="textlink">{msg.text.phoneLink.text}</a>.
					</div>
					<div class="Text-greeting2 fancy">{screen.content.greeting2}</div>
				</div>
			}
			{	serverResponse === "ok" &&
				/* DO NOT CHANGE: "conversion-questionnaire" is used for conversion tracking by Google Tag Manager */
				<div class="conversion-questionnaire">
					<div class="heading">{screen.content.title}</div>
					<img class="Text-icon" src={screen.content.icon}/>
					{
						screen.content.paragraphs.map(it =>
							<div class="text">{it}</div>
						)
					}
					<div class="Text-greeting1">{screen.content.greeting1}</div>
					<div class="Text-greeting2 fancy">{screen.content.greeting2}</div>
				</div>
			}
		</div>
	);
};
