import * as sharedLocale	from "@geotoura/shared/sharedLocale";
import * as i18n			from "@geotoura/shared/i18n";

import { messages as de } from "@geotoura/fb/locale_de";
import { messages as en } from "@geotoura/fb/locale_en";
import { messages as it } from "@geotoura/fb/locale_it";

export type Messages = Readonly<{
	nav: {
		start:		string,
		back:		string,
		continue:	string,
		skip:		string,
		send:		string,
		finish:		string,
	},
	closeButton: {
		sr:	string,
	},
	text: {
		errorHeading:	string,
		errorText1:		string,
		errorText2:		string,
		mailLink:		sharedLocale.Link,
		errorText3:		string,
		phoneLink:		sharedLocale.Link,
	},
	modal: {
		close:	string,
		submit:	string,
	},
	selectlist: {
		selectRegion:	string,
	},
}>;

export namespace Messages {
	export const of:i18n.Localized<Messages>	= { de, en, it };
}
