import { Arrays }	from "ts-base/arrays";

import * as primitive	from "@geotoura/shared/primitive";
import * as fbModel		from "@geotoura/shared/fbModel";
import * as commonModel	from "@geotoura/shared/commonModel";

export type Model = {
	questionnaireId:	fbModel.QuestionnaireId,
	questionnaireName:	string,
	questionnaireData:	QuestionnaireData | null,
	formDirty:			boolean,
	serverResponse:		string,
	displayMode:		DisplayMode,
	pageInfo:			PageInfo,
};

export namespace Model {
	export const initial = (questionnaireId:fbModel.QuestionnaireId, questionnaireName:string, pageInfo:PageInfo):Model => ({
		questionnaireId:	questionnaireId,
		questionnaireName:	questionnaireName,
		questionnaireData:	null,
		formDirty:			false,
		serverResponse:		"",
		displayMode:		"integrated",
		pageInfo,
	});
}

export type PageInfo = FrontPageInfo | RoutePageInfo | RegionPageInfo | TerritoryPageInfo;

type FrontPageInfo = {
	type: "front",
};

type RoutePageInfo = {
	type: "route",
	routeId: commonModel.ExampleRouteId,
};

type RegionPageInfo = {
	type:		"region",
	routeId:	commonModel.ExampleRouteId,
	regionId:	commonModel.RegionId,
};

export type TerritoryPageInfo = {
	type:			"territory",
	territoryId:	commonModel.TerritoryId,
};

export type DisplayMode = "fullscreen"|"integrated";

export type QuestionnaireData = {
	// TODO fbtype can we ensure questionnaire.screens and answers are type-aligned?
	questionnaire:	fbModel.Questionnaire,
	answers:		ReadonlyArray<fbModel.Answer>,
	currentScreen:	number,
};

export type FormValidity	= {
	dirty:		boolean,
	checked:	boolean,
	email:		boolean,
};

export namespace FormValidity {
	export const fullyValid = (formValidity:FormValidity):boolean =>
		formValidity.dirty		&&
		formValidity.checked	&&
		formValidity.email;
}

//-----------------------------------------------------------------------------
//## setup

export const initQuestionnaire	= (questionnaire:fbModel.Questionnaire):QuestionnaireData	=> ({
	questionnaire:	questionnaire,
	answers:		initAnswers(questionnaire.screens),
	currentScreen:	0,
});

// NOTE the last screen is just a "thanks for contacting us", no answer is expected there
const initAnswers	= (screens:ReadonlyArray<fbModel.Screen>):ReadonlyArray<fbModel.Answer> =>
	Arrays.init(screens).map(initAnswer);

const initAnswer = (screen:fbModel.Screen):fbModel.Answer => {
	switch (screen.stId) {
		case "RADIOSTART":
			const contentSTRadiostart	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"RADIOSTART",
				content: {
					radioItemId:	null,
					keyRadio:		contentSTRadiostart.radioGroup.keyRadio,
					valueRadio:		null,
					valueDefault:	null,
					mailRadioKey:	contentSTRadiostart.radioGroup.mailKey,
					mailRadioValue:	"",
				},
				show:					true,
			};
		case "SELECTLIST":
			const contentSTSelectList	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"SELECTLIST",
				content: {
					selected:			[],
					selectedDefault:	[],
					keyList:			contentSTSelectList.keyList,
					mailListKey:		contentSTSelectList.mailListKey,
				},
				show:					true,
			};
		case "DATE":
			const contentSTDate	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"DATE",
				content: {
					counter1: {
						keyCounter:		contentSTDate.counter1.keyCounter,
						counterValue:	contentSTDate.counter1.counterDefault,
						counterDefault:	contentSTDate.counter1.counterDefault,
						mailKey:		contentSTDate.counter1.mailKey,
					},
					counter2: {
						keyCounter:		contentSTDate.counter2.keyCounter,
						counterValue:	contentSTDate.counter2.counterDefault,
						counterDefault:	contentSTDate.counter2.counterDefault,
						mailKey:		contentSTDate.counter2.mailKey,
					},
					date:				"",
					dateDefault:		"",
					keyDate:			contentSTDate.keyDate,
					mailDateKey:		contentSTDate.mailDateKey,
				},
				show:					true,
			};
		case "FLIGHT":
			const contentSTFlight	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"FLIGHT",
				content: {
					radioItemId:		null,
					valueDefault:		null,
					keyRadio:			contentSTFlight.radioGroup.keyRadio,
					keyCountry:			contentSTFlight.keyCountry,
					keyPostcode:		contentSTFlight.keyPostcode,
					valueRadio:			null,
					countryValue:		"",
					valuePostcode:		"",
					mailModalTitle:		contentSTFlight.mailModalTitle,
					mailCountryTitle:	contentSTFlight.mailCountryTitle,
					mailPostcodeTitle:	contentSTFlight.mailPostcodeTitle,
					mailRadioKey:		contentSTFlight.radioGroup.mailKey,
					mailRadioValue:		"",
				},
				show:					true,
			};
		case "HOTEL":
			const contentSTHotel	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"HOTEL",
				content: {
					counter: {
						keyCounter:		contentSTHotel.counter.keyCounter,
						counterValue:	contentSTHotel.counter.counterDefault,
						counterDefault:	contentSTHotel.counter.counterDefault,
						mailKey:		contentSTHotel.counter.mailKey,
					},
					radioItemId:		null,
					keyRadio:			contentSTHotel.radioGroup.keyRadio,
					valueRadio:			null,
					valueDefault:		null,
					mailRadioKey:		contentSTHotel.radioGroup.mailKey,
					mailRadioValue:		"",
				},
				show:					true,
			};
		case "COUNTERLIST":
			const contentSTCounterList	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"COUNTERLIST",
				content: {
					counterValues: contentSTCounterList.list.map(it => ({
						keyCounter:		it.keyCounter,
						counterValue:	it.counterDefault,
						counterDefault:	it.counterDefault,
						mailKey:		it.mailKey,
					})),
				},
				show:					true,
			};
		case "SLIDERLIST":
			const contentSTSliderList	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"SLIDERLIST",
				content: {
					sliderValues: contentSTSliderList.sliders.map(it => ( {
						keySlider:		it.keySlider,
						sliderValue:	fbModel.SliderValues.neutral,
						sliderDefault:	fbModel.SliderValues.neutral,
						mailValue:		"",
						mailKey:		it.mailKey,
					})),
				},
				show:					true,
			};
		case "BUDGET":
			const contentSTBudget	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"BUDGET",
				content: {
					budget:				fbModel.DayAverages.budgetEuroToPercent(contentSTBudget.dayDefault, contentSTBudget.dayAverages),
					budgetDefault:		fbModel.DayAverages.budgetEuroToPercent(contentSTBudget.dayDefault, contentSTBudget.dayAverages),
					dayAverages:		contentSTBudget.dayAverages,
					mailBudgetKey:		contentSTBudget.mailBudgetKey,
				},
				show:					true,
			};
		case "CONTACT":
			const contentSTContact	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"CONTACT",
				content: {
					keyComment:			contentSTContact.keyComment,
					keyMail:			contentSTContact.keyMail,
					keyCheckbox:		contentSTContact.keyCheckbox,
					returnTextarea:		"",
					returnDefault:		"",
					returnMail:			"",
					returnCheckbox:		false,
					mailContactKey:		contentSTContact.mailContactKey,
				},
				show:					true,
			};
		case "TEXT":
			// const contentSTText	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"TEXT",
				// this is AText: a text screen does not need answer data
				content:				{},
				show:					true,
			};
		case "DECISION":
			const contentSTDecision	= screen.content;
			return {
				screenId:				screen.id,
				questionnaireScreenId:	screen.questionnaireScreenId,
				stId:					"DECISION",
				content: {
					valueRadio:			null,
					valueDefault:		null,
					keyRadio:			contentSTDecision.radioGroup.keyRadio,
					radioItemId:		null,
					mailRadioKey:		contentSTDecision.radioGroup.mailKey,
					mailRadioValue:		"",
				},
				show:					true,
			};
	}
};

//-----------------------------------------------------------------------------
//## contact form

export const currentFormValidity	= (model:Model):FormValidity	=> {
	if (model.questionnaireData !== null) {
		const answers	= model.questionnaireData.answers;

		// NOTE fbApi.ts guarantees the second to last screen is an AContact
		// NOTE actions.getQuestionnaire _removes_ the last answer (TEXT) the contact is the last one in this case
		// TODO fbtype ugly
		const answer:fbModel.AnswerOfContact	= answers[answers.length-1] as fbModel.AnswerOfContact;

		return {
			dirty:		model.formDirty,
			checked:	answer.content.returnCheckbox,
			email:		primitive.EmailAddress.fromString(answer.content.returnMail) !== null,
		};
	}
	else {
		// during initialization, model.answers is null
		return {
			dirty:		false,
			checked:	false,
			email:		false,
		};
	}
};

//-----------------------------------------------------------------------------
//## screen mode and progress

/*
Assumptions:
1) There is a variable number of screens
2) the pre-last screen contains the last form elements – they will be sent to the server when clicking next and the form is valid
3) the last screen contains just a thank you note - it will lead back to the main page
*/
export type ScreenMode	= "first"|"other"|"send"|"thanks";

export const currentScreenMode	= (data:QuestionnaireData):ScreenMode	=> {
	const screens	= data.questionnaire.screens.length;
	const current	= data.currentScreen;
	return	current === 0			? "first"
		:	current === screens - 2	? "send"
		:	current === screens - 1 ? "thanks"
		:	"other";
};

/** returns a number between 0 and 1 (inclusive) */
export const progress	= (data:QuestionnaireData):number =>
	// the "thanks"-screen has index screens.length-1. this counts as 100% done.
	data.currentScreen / (data.questionnaire.screens.length-1);

/*
// NOTE we could use these instead, but that lead to jumpy progress
const doneSreens	= data.answers.filter((answer, idx) => answer.show && idx < data.currentScreen);
const shownScreens	= data.answers.filter(it => it.show);
*/
