import { Branded }		from "ts-base/branded";
import { Fn }			from "ts-base/fn";
import { Validated }	from "ts-base/validated";
import * as QS			from "ts-base/validation/query";

import * as primitive	from "@geotoura/shared/primitive";
import * as i18n		from "@geotoura/shared/i18n";

export * from "ts-base/validation/query";

// TODO move these into primitive/commonModel?

export const LanguageCode:QS.ParameterSchema<i18n.LanguageCode>			= i18n.LanguageCode.validateString;

export const NaturalNumber:QS.ParameterSchema<primitive.NaturalNumber>	= primitive.NaturalNumber.validateString;

export const identifier	= <B extends string>(brander:Fn<number, Branded<number, B>>):QS.ParameterSchema<Branded<number, B>>	=>
	Validated.mapKleisli(NaturalNumber)(brander);
