/* eslint-disable @stylistic/comma-dangle */

import { sharedLocale }	from "@geotoura/shared/sharedLocale_de";

import { Messages } from "@geotoura/fb/locale";

export const messages:Messages = {
	nav: {
		start:		"Reiseplanung starten",
		back:		"Zurück",
		continue:	"Weiter",
		skip:		"Überspringen",
		send:		"Abschicken",
		finish:		"Zurück zur Startseite",
	},
	closeButton: {
		sr:	"Schließen"
	},
	text: {
		errorHeading:	"Fehler",
		errorText1:		"Beim Speichern Ihrer Daten ist leider ein Fehler aufgetreten.",
		errorText2:		"Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal oder kontaktieren Sie uns unter ",
		mailLink:		sharedLocale.email.info,
		errorText3:		" oder ",
		phoneLink:		sharedLocale.phone,
	},
	modal: {
		close:	"Schließen",
		submit:	"OK"
	},
	selectlist: {
		selectRegion:	"Region auswählen"
	}
};
