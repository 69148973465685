/* eslint-disable @stylistic/comma-dangle */

import { sharedLocale }	from "@geotoura/shared/sharedLocale_it";

import { Messages }	from "@geotoura/fb/locale";

export const messages:Messages = {
	nav: {
		start:		"Iniziare la pianificazione del viaggio",
		back:		"Indietro",
		continue:	"Avanti",
		skip:		"Salta",
		send:		"Invia",
		finish:		"Tornare alla pagina iniziale",
	},
	closeButton: {
		sr:	"Chiudere"
	},
	text: {
		errorHeading:	"Errore",
		errorText1:		"Purtroppo si è verificato un errore durante il salvataggio.",
		errorText2:		"Preghiamo di riprovare più tardi o contattarci al numero 0942 264463",
		mailLink:		sharedLocale.email.info,
		errorText3:		" o ",
		phoneLink:		sharedLocale.phone,
	},
	modal: {
		close:	"Chiudere",
		submit:	"OK"
	},
	selectlist: {
		selectRegion:	"Selezionare la regione"
	}
};
