import { Branded }		from "ts-base/branded";
import { Fn }			from "ts-base/fn";
import { Validated }	from "ts-base/validated";
import * as JS			from "ts-base/validation/json";

import * as time		from "@geotoura/shared/time";
import * as primitive	from "@geotoura/shared/primitive";
import * as i18n		from "@geotoura/shared/i18n";

export * from "ts-base/validation/json";

// TODO ts-base JS.string and JS.number should not be used here - but they do exactly the right thing

export const LanguageCode:JS.Schema<i18n.LanguageCode>			= Validated.andThen(JS.string, i18n.LanguageCode.validateString);

export const CountryId:JS.Schema<primitive.CountryId>			= Validated.andThen(JS.string, primitive.CountryId.validateString);

export const NonEmptyString:JS.Schema<primitive.NonEmptyString>	= Validated.andThen(JS.string, primitive.NonEmptyString.validateString);
export const EmailAddress:JS.Schema<primitive.EmailAddress>		= Validated.andThen(JS.string, primitive.EmailAddress.validateString);
export const IsoDate:JS.Schema<time.IsoDate>					= Validated.andThen(JS.string, time.IsoDate.validateString);
export const NaturalNumber:JS.Schema<primitive.NaturalNumber>	= Validated.andThen(JS.number, primitive.NaturalNumber.validateNumber);

export const identifier	= <B extends string>(brander:Fn<number, Branded<number, B>>):JS.Schema<Branded<number, B>>	=>
	Validated.mapKleisli(NaturalNumber)(brander);
