/* eslint-disable @stylistic/comma-dangle */

import { sharedLocale }	from "@geotoura/shared/sharedLocale_de";

import { Messages } from "@geotoura/fb/locale";

export const messages:Messages = {
	nav: {
		start:		"Start planning your trip",
		back:		"Back",
		continue:	"Continue",
		skip:		"Skip",
		send:		"Send",
		finish:		"Back to the homepage",
	},
	closeButton: {
		sr:	"Close"
	},
	text: {
		errorHeading:	"Error",
		errorText1:		"Unfortunately, an error occurred while saving your data.",
		errorText2:		"Please try again at a later date or contact us at ",
		mailLink:		sharedLocale.email.info,
		errorText3:		" or ",
		phoneLink:		sharedLocale.phone,
	},
	modal: {
		close:	"Close",
		submit:	"OK"
	},
	selectlist: {
		selectRegion:	"Select region"
	}
};
